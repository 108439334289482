body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom CSS */

.footer-title{
  border-bottom: 2px solid #298ac8;
  padding-bottom: 15px;
  width: 100%;
  margin: 16px 0;
}


.statistic-card
{
    position:absolute;
    left:40%;
    top:-20px;
    height: 60px;
    width: 60px;
    border-radius:50%;
}



/* Disabled Button */

.btn-disabled{
  background-color: #f1f1f1;
  color: #c5c9cd;
  cursor: not-allowed;
}
.btn-disabled:hover{
  background-color: #f1f1f1!important;
  color: #c5c9cd!important;
  cursor: not-allowed;
}


.marquee-bg{
  background-color: rgb(255, 62, 62);
}

.marquee {
  animation: marquee 40s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
